(function(w, d){
	var share = d.querySelector('div.share');
	var links = Array.prototype.slice.call( share.getElementsByTagName('a') );

	var clicked = false;
	var to = [];

	share.querySelector('strong').addEventListener('click',function(){
		if (share.classList.contains('open')){
			share.classList.remove('open');
			clicked = false;
		}else{
			share.classList.add('open');
			clicked = true;
			clearTimeout(to);
		}
		
	});
	
	[].forEach.call(links,function(link){
		link.addEventListener('click',function(e){
			// e.preventDefault();
			share.classList.remove('open');
			clicked = false;
		});
	});
	share.addEventListener('mousemove',function(e){
		if (clicked){
			share.classList.add('open');
			clearTimeout(to);
		}
	});
	share.addEventListener('mouseleave',function(e){
		to = setTimeout(function(){
			share.classList.remove('open');
			clicked = false;
		},500);
	});
})(window, document);