
function whichTransitionEvent(){
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
      'transition':'transitionend',
      'OTransition':'oTransitionEnd',
      'MozTransition':'transitionend',
      'WebkitTransition':'webkitTransitionEnd'
    }

    for(t in transitions){
        if( el.style[t] !== undefined ){
            return transitions[t];
        }
    }
}

function fire( elem, type ) {

	if (document.createEvent) {
        evt = document.createEvent("MouseEvents");
        evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    (evt) ? elem.dispatchEvent(evt) : (elem.click && elem.click());


}

(function(w,d){
	 document.addEventListener('DOMContentLoaded', function() {
        FastClick.attach(document.body);
    }, false);

	var slugsArr = [];
	var startingleft = 0;
	var length = d.querySelectorAll('.project').length;
	var y = 0;
	var x = 0;
	var loading = true;
	var displayThumbs = true;
	var getImageLightness = function(src,callback) {
	    var img = document.createElement("img");
	    img.src = src;
	    img.style.display = "none";
	    // var brightness = 0;
	    document.body.appendChild(img);

	    var colorSum = 0;

	    img.onload = function() {
	        var canvas = document.createElement("canvas");
	        canvas.width = this.width;
	        canvas.height = this.height;

	        var ctx = canvas.getContext("2d");
	        ctx.drawImage(this,0,0);

	        var imagedata = ctx.getImageData(0,0,canvas.width,canvas.height);
	        var data = imagedata.data;
	        var r,g,b,avg;

	        for(var x = 0, len = data.length; x < len; x+=4) {
	            r = data[x];
	            g = data[x+1];
	            b = data[x+2];
	            avg = Math.floor((r+g+b)/3);
	            colorSum += avg;
	        }

        	var brightness = Math.floor(colorSum / (this.width*this.height));
	        img.parentNode.removeChild(img);
	    	callback(brightness);
	    }

	    
	    
	}
	function colourCallback(b) {
		var keys = d.querySelectorAll('.key');
		// console.log(b);
		if(b < 100) {
			[].forEach.call(keys, function(key){
				key.classList.add('dark');
			});
			
		} else {
			[].forEach.call(keys, function(key){
				key.classList.remove('dark');
			});
		}
	}
	var load_images = function(){
		var images = d.querySelectorAll('.project img:first-child');
		[].forEach.call(images, function(img){
			var obj = new Image();
			obj.src = img.getAttribute('data-src');
			obj.onload = function(){
				img.src = obj.src;
				img.classList.add('loaded');
			}

		});
		d.querySelectorAll('.project.active img')[0].classList.add('active');
		var images = d.querySelectorAll('.project.active img:not(.loaded)');
		[].forEach.call(images, function(img){
			var obj = new Image();
			obj.src = img.getAttribute('data-src');
			obj.onload = function(){
				img.src = obj.src;
				img.classList.add('loaded');
				if (img.classList.contains('active')){
					getImageLightness(d.querySelector('.project.active img.active').getAttribute('src'), colourCallback);
				}
			}
		});
		
		if (images.length){
			images[0].parentNode.classList.add('loaded');
		}
		
		

		var active = d.querySelector('.project.active');

		if (active.nextElementSibling != null){
			var images = active.nextElementSibling.querySelectorAll('img:not(.loaded)');
			[].forEach.call(images, function(img){
				var obj = new Image();
				obj.src = img.getAttribute('data-src');
				obj.onload = function(){
					img.src = obj.src;
					img.classList.add('loaded');
				}

			});
			if (images.length){
				images[0].parentNode.classList.add('loaded')
			}
			if (active.nextElementSibling.getAttribute('name') == d.querySelectorAll('.project')[0].getAttribute('name')){
				var images =  d.querySelectorAll('.project')[0].querySelectorAll('img:not(.loaded)');
				[].forEach.call(images, function(img){
					var obj = new Image();
					obj.src = img.getAttribute('data-src');
					obj.onload = function(){
						img.src = obj.src;
						img.classList.add('loaded');
					}
				});
				if (images.length){
					images[0].parentNode.classList.add('loaded')
				}
				var images =  d.querySelectorAll('.project')[1].querySelectorAll('img:not(.loaded)');
				[].forEach.call(images, function(img){
					var obj = new Image();
					obj.src = img.getAttribute('data-src');
					obj.onload = function(){
						img.src = obj.src;
						img.classList.add('loaded');
					}
				});
				if (images.length){
					images[0].parentNode.classList.add('loaded')
				}
			}
		}
		if (active.previousElementSibling){
			var images = active.previousElementSibling.querySelectorAll('img:not(.loaded)');
			[].forEach.call(images, function(img){
				var obj = new Image();
				obj.src = img.getAttribute('data-src');
				obj.onload = function(){
					img.src = obj.src;
					img.classList.add('loaded');
				}
			});
		}else{
			var images =  d.querySelectorAll('.project')[d.querySelectorAll('.project').length - 1].querySelectorAll('img:not(.loaded)');
			[].forEach.call(images, function(img){
				var obj = new Image();
				obj.src = img.getAttribute('data-src');
				obj.onload = function(){
					img.src = obj.src;
					img.classList.add('loaded');
				}
			});

			if (images.length){
				images[0].parentNode.classList.add('loaded')
			}
			var images =  d.querySelectorAll('.project')[d.querySelectorAll('.project').length - 2].querySelectorAll('img:not(.loaded)');
			[].forEach.call(images, function(img){
				var obj = new Image();
				obj.src = img.getAttribute('data-src');
				obj.onload = function(){
					img.src = obj.src;
					img.classList.add('loaded');

				}
			});
			if (images.length){
				images[0].parentNode.classList.add('loaded')
			}
		}
		// load next and previous
	};
	var load_rest = function(){
		var to_load = d.querySelectorAll('.project:not(.loaded)');
		[].forEach.call(to_load, function(proj){
			var images = proj.querySelectorAll('img');
			[].forEach.call(images, function(img){
				var obj = new Image();
				obj.src = img.getAttribute('data-src');
				obj.onload = function(){
					img.src = obj.src;
					img.classList.add('loaded');
				}
			});
		});
	};
	var construct_thumbnails = function(){
		var projects = d.querySelectorAll('.project');
		var thumb = d.querySelector('.thumbnails');
		[].forEach.call(projects, function(project){
			var set = d.createElement('div');
			set.classList.add('set');
			set.setAttribute('data-name',project.getAttribute('data-name'));
			var images = project.querySelectorAll('img');
			[].forEach.call(images, function(img){
				// img.setAttribute('src',img.getAttribute('data-src'));
				var newimg = img.cloneNode(true);
				newimg.removeAttribute('style');
				set.appendChild(newimg);

			});
			thumb.appendChild(set)
		});
	};
	var thumbnails = function(){
		var thumbs = d.querySelector('.thumbnails');
		if (d.querySelector('.thumbnails .set.active')){
			d.querySelector('.thumbnails .set.active').classList.remove('active');	
		}
		if (d.querySelector('.thumbnails img.active')){
			d.querySelector('.thumbnails img.active').classList.remove('active');
		}
		
		var project = d.querySelector('.project.active').getAttribute('data-name');
		var set = d.querySelector('.thumbnails .set[data-name="'+project+'"]');
		set.classList.add('active');


		var images = d.querySelectorAll('.set.active img');
		var count = images.length;
		var i = 0;
		var p1 = new Promise(function(resolve, reject){
			// d.querySelector('.thumbnails').style.display = 'none';
			d.querySelector('.thumbnails').classList.add('loading');
			[].forEach.call(images, function(img){
				// img.setAttribute('src',img.getAttribute('data-src'));
				var obj = new Image();
				
				obj.onload = function(){
					img.onload = function(){
						i++;
						if (i == count){
							resolve();	
						}
					};
					img.src = obj.src;

					img.classList.add('loaded');
				};
				obj.src = img.getAttribute('data-src');
			});
		}).then(function(){
			// thumbs.classList.remove('hide');
			if (!displayThumbs){
				thumbs.classList.remove('hidden');	
			}
			// d.querySelector('.thumbnails').style.display = 'block';
			d.querySelector('.thumbnails').classList.remove('loading');
		});
		images[0].classList.add('active');

		var active = d.querySelector('.set.active .active');
		// console.log(active);
		if (active.classList.contains('loaded')){

			d.querySelector('.thumbnails').classList.remove('loading');
		}

		var active = d.querySelector('.set.active');
		if (active.nextElementSibling){
			var images = active.nextElementSibling.querySelectorAll('img');
			[].forEach.call(images, function(img){
				// img.setAttribute('src',img.getAttribute('data-src'));
			});
		}
		if (active.previousElementSibling){
			var images = active.previousElementSibling.querySelectorAll('img');
			[].forEach.call(images, function(img){
				// img.setAttribute('src',img.getAttribute('data-src'));
			});

		}

	};
	var splash = function(){
		// hide inital screen
		var hide = function(){
			d.querySelector('section div.loading').classList.add('splash');	
		};
		var start = function(){
			setTimeout(function(){
				d.querySelector('section .loading').classList.add('hide');
				d.documentElement.classList.add('loaded');
				setTimeout(function(){
					d.querySelector('section .loading').style.height = 0;
					d.querySelector('section .loading').style.width = 0;
				},500);
				if ((w.location.hash != '#!/Biography') && (w.location.hash != '#!/Contact')){
					// setTimeout(function(){
						fire(d.querySelectorAll('.thumbnails .set.active img.active')[0], 'click');
					// },7500);
				}else{
					if (w.location.hash == '#!/Biography'){
						d.querySelector('.content-inner.biography').classList.add('active');
						caption('Biography');
					}
					if (w.location.hash == '#!/Contact'){
						d.querySelector('.content-inner.contact').classList.add('active');
						caption('Contact');
					}
				}
			},7500);
		};
		
		
		var images = d.querySelectorAll('.loading-splash img.anim');
		[].forEach.call(images, function(img){

			// img.setAttribute('src',img.getAttribute('src')+'?v='+ new Date().getTime());
			

			var newimg = img.cloneNode('true');
			// var newimg = new Image();
			var obj = new Image();
			obj.src = newimg.getAttribute('src')+'?v='+ new Date().getTime()+'hello'
			obj.onload = function(){
				newimg.src = obj.src;
				newimg.classList.add('loaded');
				hide();
				start();
			}
			newimg.setAttribute('src',newimg.getAttribute('src')+'?v='+ new Date().getTime()+'hello');
			newimg.classList.remove('anim');
			img.parentNode.appendChild(newimg);
			img.parentNode.removeChild(img);
			// img.parentNode.appendChild(newimg);
		});
		
		
	};
	var construct_projects = function(){
		var clone = d.querySelector('.project:first-of-type').cloneNode(true);
		clone.classList.remove('active');
		d.querySelector('.projects').appendChild(clone);
		//d.querySelectorAll('.project');
	};
	var construct_images = function(){
		var projects = d.querySelectorAll('.project');
		[].forEach.call(projects, function(project){
			var newimg = project.querySelectorAll('img')[0].cloneNode(true);
			project.appendChild(newimg);
		});
	};

	var thumbnails_events = function(){
		var sets = d.querySelectorAll('.thumbnails .set');
		[].forEach.call(sets, function(set){
			var images = set.querySelectorAll('img');
			[].forEach.call(images, function(image){
				image.addEventListener('click',function(e){
					if (d.querySelectorAll('.thumbnails .set img.active')){
						d.querySelectorAll('.thumbnails .set img.active')[0].classList.remove('active');
					}
					image.classList.add('active');
					d.querySelector('.projects').style.top = Array.prototype.indexOf.call(images, image) * -100 + '%';
					caption();
				});
			});
		});
	};

	var caption = function(message){
		// var e = new Error();
		// console.log(e.stack);
		// console.log(e);
		// console.log('wtfs');
		// alert('asdf');
		var first_line = d.querySelectorAll('.tagline_wrapper span')[0];
		var second_line = d.querySelectorAll('.tagline_wrapper span')[1];
		first_line.textContent = d.querySelector('.project.active').getAttribute('data-name');
		second_line.textContent = d.querySelector('.thumbnails .set.active img.active').getAttribute('alt');
		if (typeof message != 'undefined'){

			first_line.textContent = message;
			second_line.textContent = '';
		}

	};

	var navigation = function(direction){
		var move_down = function(){
			y++;
			if (y < (d.querySelectorAll('.project.active img').length - 1)){
				d.querySelector('.projects').style.top = y * -100 + '%';
				d.querySelector('.thumbnails .set.active img.active').classList.remove('active');
				d.querySelectorAll('.thumbnails .set.active img')[y].classList.add('active');
				d.querySelector('.project.active img.active').classList.remove('active');
				d.querySelectorAll('.project.active img')[y].classList.add('active');
			}
			if (y == (d.querySelectorAll('.project.active img').length - 1)){
				d.querySelector('.projects').style.top = y * -100 + '%';
				d.querySelector('.thumbnails .set.active img.active').classList.remove('active');
				d.querySelectorAll('.thumbnails .set.active img')[0].classList.add('active');
				d.querySelector('.project.active img.active').classList.remove('active');
				d.querySelectorAll('.project.active img')[0].classList.add('active');
				setTimeout(function(){
					y = 0;
					d.querySelector('.projects').classList.add('stable');
					d.querySelector('.projects').style.top = '0%';
					setTimeout(function(){
						d.querySelector('.projects').classList.remove('stable');
					},500);	
				},500);
			}
			setTimeout(function(){
				caption();
				getImageLightness(d.querySelector('.project.active img.active').getAttribute('src'), colourCallback);
			},300);
		};
		var move_up = function(){
			y--;
			if ((y < (d.querySelectorAll('.project.active img').length - 1)) && ( y >= 0)){
				d.querySelector('.projects').style.top = y * -100 + '%';
				d.querySelector('.thumbnails .set.active img.active').classList.remove('active');
				d.querySelectorAll('.thumbnails .set.active img')[y].classList.add('active');
				d.querySelector('.project.active img.active').classList.remove('active');
				d.querySelectorAll('.project.active img')[y].classList.add('active');
			}
			if (y < 0){
				y = d.querySelectorAll('.project.active img').length - 2;
				d.querySelector('.projects').classList.add('stable');
				d.querySelector('.projects').style.top = (d.querySelectorAll('.project.active img').length - 1) * -100 +'%';
				setTimeout(function(){
					d.querySelector('.projects').classList.remove('stable');
					setTimeout(function(){
						d.querySelector('.projects').style.top = y * -100 + '%';
						d.querySelector('.thumbnails .set.active img.active').classList.remove('active');
						d.querySelectorAll('.thumbnails .set.active img')[y].classList.add('active');
						d.querySelector('.project.active img.active').classList.remove('active');
				d.querySelectorAll('.project.active img')[y].classList.add('active');
					},450);	
				},50);
			}
			setTimeout(function(){
				caption();
				getImageLightness(d.querySelector('.project.active img.active').getAttribute('src'), colourCallback);
			},300);
		};
		// left and right
		var move_left = function(){
			// x--;
			d.querySelector('.projects').style.top = '0%';
			y = 0;
			x--;
			if ((x < (d.querySelectorAll('.project').length - 1)) && (x >= 0)){
				d.querySelector('.project.active img.active').classList.remove('active');
				d.querySelector('.project.active').classList.remove('active');
				d.querySelectorAll('.project')[x].classList.add('active');
				d.querySelectorAll('.project.active img')[0].classList.add('active');
				d.querySelector('.projects').style.left = x * -100 + '%';
				// load_images();
			}
			
			if (x < 0){

				x = d.querySelectorAll('.project').length - 2;
				d.querySelector('.projects').classList.add('stable');
				d.querySelector('.projects').style.left = (d.querySelectorAll('.project').length - 1) * -100 +'%';
				d.querySelector('.project.active').classList.remove('active');
				d.querySelectorAll('.project')[x].classList.add('active');

				// load_images();

				setTimeout(function(){
					d.querySelector('.projects').classList.remove('stable');
					setTimeout(function(){
						d.querySelector('.projects').style.left = x * -100 + '%';
					},450);	
				},50);
			}

			w.location.hash = '#!/'+d.querySelector('.project.active').getAttribute('id');
			thumbnails();
			setTimeout(function(){
				caption();
				getImageLightness(d.querySelector('.project.active img.active').getAttribute('src'), colourCallback);
			},300);
			["transitionend", "webkitTransitionEnd", "mozTransitionEnd"].forEach(function(transition) {
			     document.addEventListener(transition, function(){
			     	load_images();
			     }, false);
			});
		};
		var move_right = function(){
			d.querySelector('.projects').style.top = '0%';
			y = 0;
			x++;
			if (x < (d.querySelectorAll('.project').length - 1)){
				d.querySelector('.projects').style.left = x * -100 + '%';
				d.querySelector('.project.active img.active').classList.remove('active');
				d.querySelector('.project.active').classList.remove('active');
				d.querySelectorAll('.project')[x].classList.add('active');
				d.querySelectorAll('.project.active img')[0].classList.add('active');
				// load_images();
			}
			// console.log(x);
			if (x == (d.querySelectorAll('.project').length - 1)){
				d.querySelector('.projects').style.left = x * -100 + '%';
				d.querySelector('.project.active').classList.remove('active');
				d.querySelectorAll('.project')[0].classList.add('active');
				d.querySelectorAll('.project.active img')[0].classList.add('active');
				setTimeout(function(){
					x = 0;
					d.querySelector('.projects').classList.add('stable');
					d.querySelector('.projects').style.left = '0%';
					setTimeout(function(){
						d.querySelector('.projects').classList.remove('stable');
					},500);	
				},500);
			}

			w.location.hash = '#!/'+d.querySelector('.project.active').getAttribute('id');
			thumbnails();
			["transitionend", "webkitTransitionEnd", "mozTransitionEnd"].forEach(function(transition) {
			     document.addEventListener(transition, function(){
			     	load_images();
			     }, false);
			});
			setTimeout(function(){
				caption();
				getImageLightness(d.querySelector('.project.active img.active').getAttribute('src'), colourCallback);
			},300);
		};
		

		
		switch (direction){
			case 'down': move_down();
				break;
			case 'up': move_up();
				break;
			case 'left': move_left();
				break;
			case 'right': move_right();
				break;
		}
	};

	var nav_timeout = [];
	var navigation_events = function(){
		d.body.addEventListener('keydown',function(e){
			if ((loading) || (d.body.classList.contains('content_loaded'))){
				return false;
			}
			clearTimeout(nav_timeout);
			nav_timeout = setTimeout(function(){
				switch (e.keyCode){
					case 40: navigation('down');
						break;
					case 39: navigation('right');
						break;
					case 38: navigation('up');
						break;
					case 37: navigation('left');
						break;
				}
			},500);
		});
		var arrow_links = d.querySelectorAll('.key');
		[].forEach.call(arrow_links, function(link){
			link.addEventListener('click',function(){
				if ((loading) || (d.body.classList.contains('content_loaded'))){
					return false;
				}
				clearTimeout(nav_timeout);
				nav_timeout = setTimeout(function(){
					switch (link.getAttribute('id')){
						case 'key-down': navigation('down');
							break;
						case 'key-right': navigation('right');
							break;
						case 'key-up': navigation('up');
							break;
						case 'key-left': navigation('left');
							break;
					}
				},500);
			});

		});

		var images = d.querySelectorAll('img');
		[].forEach.call(images, function(img){
			img.ondragstart = function() { return false; };
		});

		Hammer.Pan({
			threshold:40
		});
		var view = d.querySelector('.view');
		var mc = new Hammer(view);
		

		mc.on('swipeleft swiperight swipeup swipedown panleft panright panup pandown',function(e){
			
			if ((loading) || (d.body.classList.contains('content_loaded'))){
				return false;
			}
			clearTimeout(nav_timeout);
			// console.log(e.type);
			nav_timeout = setTimeout(function(){
				switch (e.type){
					case 'swipeup': navigation('down');
						break;
					case 'swipeleft': navigation('right');
						break;
					case 'swipedown': navigation('up');
						break;
					case 'swiperight': navigation('left');
						break;
					case 'panup': navigation('down');
						break;
					case 'panleft': navigation('right');
						break;
					case 'pandown': navigation('up');
						break;
					case 'panright': navigation('left');
						break;
				}
			},50);
		});




		var project_links = d.querySelectorAll('.project_link a');
		[].forEach.call(project_links, function(link){
			link.addEventListener('click',function(e){
				
				d.querySelector('.share').classList.remove('hidden');

				if ((d.body.classList.contains('content_loaded'))){
					// hide content
					d.body.classList.remove('content_loaded');
					d.querySelector('.text_content').classList.remove('open');
					d.querySelector('.content-inner').classList.remove('active');
					d.querySelector('section .content').classList.remove('open');

				}
				// console.log(d.querySelectorAll('.project.active'));
				d.querySelector('.project.active').classList.remove('active');

				var name = link.textContent;

				var target = document.querySelectorAll('.project[data-name="'+name+'"]')[0];

				// the collection you're looking in
				var nodes = document.querySelectorAll('.project');

				x = [].indexOf.call(nodes, target);

				d.querySelector('section').classList.remove('open');				

				d.querySelector('.projects').style.left = x * -100 + '%';
				

				target.classList.add('active');
				d.querySelector('.projects').classList.remove('stable');
				
				thumbnails();
				caption();
				console.log()
				load_images();
				

				nav.classList.remove('open');
				trigger.classList.remove('hidden');
				d.querySelector('.thumbnails').classList.remove('hidden');
				clicked = false;
				trigger.classList.remove('close');
			});
			link.addEventListener('mouseenter',function(){

				// d.querySelector('.projects').classList.add('stable');
				var name = link.textContent;
				var target = d.querySelectorAll('.project[data-name="'+name+'"]')[0];

				// the collection you're looking in
				var nodes = d.querySelectorAll('.project');

				var index = [].indexOf.call(nodes, target);
				
				if (d.querySelectorAll('.content.open .active').length){
					d.querySelector('.content.open .active').classList.add('isactive');
					d.querySelector('.content.open .active').classList.remove('active');
				}

				d.querySelector('.projects').style.left = index * -100 + '%';
				d.querySelector('.projects').style.top = 0 + '%';

			})
			link.addEventListener('mouseleave',function(){
				d.querySelector('.projects').style.left = x * -100 + '%';
				d.querySelector('.projects').style.top = y * -100 + '%';
				// d.querySelector('.projects').classList.remove('stable');

				if (d.querySelectorAll('.content.open .isactive').length){
					d.querySelector('.content.open .isactive').classList.add('active');
					d.querySelector('.content.open .active').classList.remove('isactive');
				}
			});
		});


		var content_links = d.querySelectorAll('.content_link a');
		[].forEach.call(content_links, function(link){
			var linkClick;
			link.addEventListener('click',function(e){
				trigger.classList.remove('close');
				d.querySelector('.projects').classList.remove('stable');
				d.body.classList.add('content_loaded');
				d.querySelector('.text_content').classList.add('open');
				if (d.querySelector('.project.active')){
					d.querySelector('.project.active').classList.remove('active');	
				}
				d.querySelectorAll('.project')[0].classList.add('active');
				d.querySelector('section .content').classList.add('open');

				d.querySelector('section').classList.add('open');

				d.querySelector('.content-inner.biography').classList.remove('active');
				d.querySelector('.content-inner.contact').classList.remove('active');
				d.querySelector('section .content .contact').classList.remove('active')
				d.querySelector('section .content .biography').classList.remove('active')

				if (link.getAttribute('href') == '#!/Biography'){
					d.querySelector('section .content .biography').classList.add('active');
					caption('Biography');
				}
				if (link.getAttribute('href') == '#!/Contact'){
					d.querySelector('section .content .contact').classList.add('active');
						caption('Contact');
				}
				setTimeout(function(){
					if (link.getAttribute('href') == '#!/Biography'){
						d.querySelector('.content-inner.biography').classList.add('active');
						caption('Biography');
					}
					if (link.getAttribute('href') == '#!/Contact'){
						d.querySelector('.content-inner.contact').classList.add('active');
							caption('Contact');
					}
				},750);

				nav.classList.remove('open');
				trigger.classList.remove('hidden');
				d.querySelector('.thumbnails').classList.remove('hidden');
				clicked = false;

				if (d.querySelectorAll('.text_content.open').length){
					setTimeout(function(){
						d.querySelector('.text_content.open').classList.remove('hide');
					},300);
				}

			});
			link.addEventListener('mouseenter',function(e){
				d.querySelector('section .content').classList.add('open');
				d.querySelector('section .content').classList.add('inactive');
				if (link.getAttribute('href') == '#!/Biography'){
					d.querySelector('section .content .biography').classList.add('active');
					d.querySelector('section .content .biography').classList.add('inactive');
				}
				if (link.getAttribute('href') == '#!/Contact'){
					d.querySelector('section .content .contact').classList.add('active');
					d.querySelector('section .content .contact').classList.add('inactive');
				}
			});
			link.addEventListener('mouseleave',function(e){

				d.querySelector('section .inactive').classList.remove('open');
				
				d.querySelector('section .content').classList.remove('inactive');
				d.querySelector('section .content div.inactive').classList.remove('inactive');
				d.querySelector('section .content div').classList.remove('active');


				d.querySelector('section .content .biography').classList.remove('active');
				d.querySelector('.content-inner.biography').classList.remove('active');
				d.querySelector('section .content .contact').classList.remove('active');
				d.querySelector('.content-inner.contact').classList.remove('active');


				if ((link.getAttribute('href') == '#!/Biography') || (link.getAttribute('href') == '#!/Contact')){
					d.querySelector('section .content').classList.remove('open');
				}

				if (link.getAttribute('href') == '#!/Biography'){
					d.querySelector('section .content .biography').classList.add('active');
					d.querySelector('.content-inner.biography').classList.add('active');
					// caption('Biography');
				}
				if (link.getAttribute('href') == '#!/Contact'){
					d.querySelector('section .content .contact').classList.add('active');
					d.querySelector('.content-inner.contact').classList.add('active');
						// caption('Contact');
				}
			});
		});
	};

	// navigation

	var nav = d.querySelector('nav');
	var share = d.querySelector('.share');
	var close = d.querySelector('.nav_close');
	var trigger = d.querySelector('.nav_trigger');
	var clicked = false
	var to = [];
	var clTo = [];
	trigger.addEventListener('click',function(){
		// console.log(nav.classList);
		// console.log(clicked);
		// if (nav.classList.contains('open')){
		// 	return false;
		// }
		clearTimeout(clTo);
		clearTimeout(to);
		if (clicked == true){
			// console.log('stugg');
			// e.preventDefault();
			d.querySelector('.projects').classList.remove('stable');
			if ((w.location.hash == '#!/Biography') || (w.location.hash == '#!/Contact')){
				d.querySelector('section .content').classList.add('open');
			}
			trigger.classList.remove('close');
			to = setTimeout(function(){
				nav.classList.remove('open');
				// trigger.classList.remove('hidden');
				// share.classList.remove('hidden');
				if ((!d.body.classList.contains('content_loaded')) || (w.innerWidth > 840)){
					share.classList.remove('hidden');	
				}
				d.querySelector('.thumbnails').classList.remove('hidden');
				// clicked = false;
				
				if (d.querySelectorAll('.text_content.open').length){
					clTo = setTimeout(function(){
						d.querySelector('.text_content.open').classList.remove('hide');
					},300);
				}
			},10);
			clicked = false;
		}else{
			// console.log('stugg');
			
			d.querySelector('.projects').classList.add('stable');

			if (d.querySelectorAll('.text_content.open').length){
				d.querySelector('.text_content.open').classList.add('hide');
			}
			if (d.documentElement.classList.contains('loading')){
				// return false;
			}
			trigger.classList.add('close');

			nav.classList.add('open');
			// trigger.classList.remove('hidden');
			// share.classList.remove('hidden');
			if ((!d.body.classList.contains('content_loaded')) || (w.innerWidth > 840)){
				share.classList.add('hidden');	
			}
			d.querySelector('.thumbnails').classList.add('hidden');;
			// clicked = false;
			
			if (d.querySelectorAll('.text_content.open').length){
				clTo = setTimeout(function(){
					d.querySelector('.text_content.open').classList.add('hide');
				},300);
			}
			
			clicked = true;
		}
		
	});
	nav.addEventListener('mousemove',function(e){
		if (clicked){
			nav.classList.add('open');
			clearTimeout(to);
			clearTimeout(clTo);
		}
	});
	nav.addEventListener('mouseleave',function(e){
		clTo = setTimeout(function(){
			if ((w.location.hash == '#!/Biography') || (w.location.hash == '#!/Contact')){
				d.querySelector('section .content').classList.add('open');
			}
			if (w.innerWidth > 840){
				to = setTimeout(function(){
					nav.classList.remove('open');
					trigger.classList.remove('hidden');
					if ((!d.body.classList.contains('content_loaded')) && (w.innerWidth > 840)){
						share.classList.remove('hidden');	
					}
					trigger.classList.remove('close');
					d.querySelector('.thumbnails').classList.remove('hidden');
					clicked = false;
					setTimeout(function(){
						d.querySelector('.projects').classList.remove('stable');
					},10);
					if (d.querySelectorAll('.text_content.open').length){
						// console.log(d.querySelectorAll('.text_content.open').length);
						setTimeout(function(){
							d.querySelector('.text_content.open').classList.remove('hide');
							
						},300);
					}
				},1500);
			}else{
				d.querySelector('.projects').classList.remove('stable');
			}
		},10);
	});

	d.addEventListener('touchend',function(e){
		// console.log(e.target);
		if ((e.target != trigger) && (e.target != d.querySelector('span.open'))){
			nav.classList.remove('open');
			trigger.classList.remove('hidden');
			if ((!d.body.classList.contains('content_loaded')) && (w.innerWidth > 840)){
				share.classList.remove('hidden');	
			}
			trigger.classList.remove('close');
			d.querySelector('.thumbnails').classList.remove('hidden');
			clicked = false;
			setTimeout(function(){
				d.querySelector('.projects').classList.remove('stable');
			},10);
			if (d.querySelectorAll('.text_content.open').length){
				// console.log(d.querySelectorAll('.text_content.open').length);
				setTimeout(function(){
					d.querySelector('.text_content.open').classList.remove('hide');
				},300);
			}
		}
	});

	close.addEventListener('click',function(e){
		
	});


	var thumbnail_toggle = function(){
		var toggle = d.querySelector('.thumbnail_toggle a');
		toggle.addEventListener('click',function(e){
			e.preventDefault();
			if (d.body.classList.contains('content_loaded')){
				return false;
			}

			var spans = toggle.querySelectorAll('span');
			[].forEach.call(spans,function(span){
				span.classList.toggle('off');
			})
			d.querySelector('.thumbnails').classList.toggle('hide');
			// if (w.innerWidth > 840){
			// 	to = setTimeout(function(){
			// 		nav.classList.remove('open');
			// 		trigger.classList.remove('hidden');
			// 		share.classList.remove('hidden');
			// 		d.querySelector('.thumbnails').classList.remove('hidden');
			// 		clicked = false;
			// 	},1000)
			// }
		});
	};

	// AJAX request
	fetch('/wp-content/themes/alexbibby/includes/ajax.php?action=pages').then(function(response){
		return response.json();	
	}).then(function(json){
		json.forEach(function(val, i){
			if(w.location.hash == '#!/'+val.slug) {
				d.querySelectorAll('.project')[i].classList.add('active');
				startingleft = i;

			}
			if (val.featured){
				var f = {slug:val.slug, id:i};
				slugsArr.push(f);
			}
		});
		
		if (w.location.hash == ''){
			var n = Math.floor(Math.random()*slugsArr.length);
			w.location.hash = '#!/'+slugsArr[n].slug;
			startingleft = slugsArr[n].id;
			d.querySelectorAll('.project')[slugsArr[n].id].classList.add('active');
			startingleft = slugsArr[n].id;
		}else if ((w.location.hash == '#!/Biography') || (w.location.hash == '#!/Contact')){
			startingleft = 0;
			d.querySelector('.text_content').classList.add('open');
			d.querySelectorAll('.project')[0].classList.add('active');
			d.body.classList.add('content_loaded');
			d.querySelector('section .content').classList.add('open');
			d.querySelector('section').classList.add('open');
			if (w.location.hash == '#!/Biography'){
				d.querySelector('section .content .biography').classList.add('active');
			}
			if (w.location.hash == '#!/Contact'){
				d.querySelector('section .content .contact').classList.add('active');
			}
			// setTimeout(function(){
			// 	if (w.location.hash == '#!/Biography'){
			// 		d.querySelector('.content-inner.biography').classList.add('active');
			// 		caption('Biography');
			// 	}
			// 	if (w.location.hash == '#!/Contact'){
			// 		d.querySelector('.content-inner.contact').classList.add('active');
			// 		caption('Contact');
			// 	}

			// },8000);
		}

		// var clone = d.querySelector('.project:first-of-type').cloneNode(true);
		// clone.classList.remove('active'); //remove active class if exists
		// d.querySelector('.projects').appendChild(clone);

		var wrapper = d.querySelector('.wrapper');
		var images = d.querySelectorAll('section img');
		var projects = d.querySelectorAll('.project');
		var thumb_wrap = d.querySelector('.thumbnails');
		// console.log(wrapper, d.documentElement.clientHeight, wrapper.offsetHeight);
		if (wrapper.offsetHeight > d.documentElement.clientHeight){
			wrapper.style.height = d.documentElement.clientHeight + 'px';
			thumb_wrap.style.right = d.documentElement.clientHeight + 'px';
			var size = d.documentElement.clientHeight - (4 * 14);
			d.querySelector('section').style.height = size + 'px';
			d.querySelector('section').style.width = size + 'px';
			// d.querySelectorAll('section img').style.height = d.documentElement.clientHeight - (4 * 16) + 'px';
			[].forEach.call(images,function(img){
				img.style.height = size + 'px';
				img.style.width = size + 'px';
			});
			[].forEach.call(projects,function(proj){
				proj.style.width = size + 'px';
			});

		}
		w.addEventListener('resize',function(){
			images = d.querySelectorAll('section img');
			wrapper.removeAttribute('style');
			d.querySelector('section').removeAttribute('style');
			thumb_wrap.removeAttribute('style');

			[].forEach.call(images,function(img){
				img.removeAttribute('style');
			});
			[].forEach.call(projects,function(proj){
				proj.removeAttribute('style');
			});
			if (wrapper.offsetHeight > d.documentElement.clientHeight){
				wrapper.style.height = d.documentElement.clientHeight + 'px';
				thumb_wrap.style.right = d.documentElement.clientHeight + 'px';
				var size = d.documentElement.clientHeight - (4 * 14);
				d.querySelector('section').style.height = size + 'px';
				d.querySelector('section').style.width = size + 'px';
				// d.querySelectorAll('section img').style.height = size + 'px';
				[].forEach.call(images,function(img){
					img.style.height = size + 'px';
					img.style.width = size + 'px';
				});
				[].forEach.call(projects,function(proj){
					proj.style.width = size + 'px';
				});
			}
		})
		
		var p1 = new Promise(function(resolve, reject){
			construct_thumbnails();
			construct_images();
			construct_projects();
			

			d.querySelector('.projects').style.width = d.querySelectorAll('.project').length * 100 + '%';
			d.querySelector('.projects').classList.add('stable');
			d.querySelector('.projects').style.left = startingleft * -100 +'%';
			x = startingleft;

			load_images();
			thumbnails();

			d.documentElement.classList.add('loading');
			d.querySelector('.project.active img:last-of-type').addEventListener('load',function(){
				resolve();
			});

		}).then(function(){
			
			d.querySelector('.projects').classList.add('loaded');
			d.querySelector('.projects').classList.remove('stable');
			splash();

		}).then(function(){
			d.documentElement.classList.remove('loading');
			d.documentElement.classList.remove('splash');
			loading = false;
			navigation_events();
			thumbnails_events();
			thumbnail_toggle();

			
			

			document.addEventListener('touchmove', function (event) {
			    event.preventDefault();
			}, false);
			
		});


	}).catch(function(e){
		console.log(e);
		console.log('failed');
	});

	

	

	
	

})(window,document);